<script setup lang="ts">
defineOptions({
  name: 'SideMenu',
})

const supabase = useSupabaseClient()
const signoutLoading = ref(false)
async function logout() {
  signoutLoading.value = true
  await supabase.auth.signOut()
  signoutLoading.value = false
  navigateTo('/login')
}
</script>

<template>
  <div class="w-[84px] min-w-[84px] h-dvh bg-gray-900 py-10 px-2 flex flex-col items-center text-gray-300 max-md:items-start max-md:px-6">
    <img src="/logo-symbol-white.svg" alt="side menu logo" class="w-[29px] mb-8 max-md:mb-10" />
    <div class="flex flex-col items-center gap-5 max-md:justify-start max-md:w-full">
      <button @click="navigateTo('/')" class="max-md:hover:bg-gray-800 max-md:rounded-[6px] max-md:w-full text-center group max-md:flex max-md:items-center max-md:justify-start max-md:gap-3">
        <span
          class="mx-auto w-12 h-12 rounded-sm flex items-center justify-center text-center group-hover:text-white transition max-md:w-fit max-md:mx-3"
        >
          <LazyIconHome :stroke-width="1.5" class="w-6 h-6" />
        </span>
        <span class="text-sm mt-1 leading-6 min-h-6 inline-block group-hover:text-white transition"
          >Home</span
        >
      </button>
      <button @click="navigateTo('/chat')" class="max-md:hover:bg-gray-800 max-md:rounded-[6px] max-md:w-full text-center group max-md:flex max-md:items-center max-md:justify-start max-md:gap-3">
        <span
          class="mx-auto w-12 h-12 rounded-sm flex items-center justify-center text-center group-hover:text-white transition max-md:w-fit max-md:mx-3"
        >
          <LazyIconMessageCircle :stroke-width="1.5" class="w-6 h-6" />
        </span>
        <span class="text-sm mt-1 leading-6 min-h-6 inline-block group-hover:text-white transition"
          >Chat</span
        >
      </button>
      <button @click="navigateTo('/manage')" class="max-md:hover:bg-gray-800 max-md:rounded-[6px] max-md:w-full text-center group max-md:flex max-md:items-center max-md:justify-start max-md:gap-3">
        <span
          class="mx-auto w-12 h-12 rounded-sm flex items-center justify-center text-center group-hover:text-white transition max-md:w-fit max-md:mx-3"
        >
          <LazyIconSettings :stroke-width="1.5" class="w-6 h-6" />
        </span>
        <span class="text-sm mt-1 leading-6 min-h-6 inline-block group-hover:text-white transition"
          >Manage</span
        >
      </button>
    </div>
    <button @click="logout" :disabled="signoutLoading" class="text-center mt-auto group max-md:flex max-md:items-center max-md:justify-start">
      <span
        class="mx-auto w-12 h-12 rounded-sm flex items-center justify-center text-center group-hover:text-white transition max-md:w-fit max-md:mx-3"
      >
        <LazyIconLoader v-if="signoutLoading" :stroke-width="1.5" class="w-6 h-6 animate-spin" />
        <LazyIconLogOut v-else :stroke-width="1.5" class="w-6 h-6" />
      </span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
@use './index.scss' as *;
</style>
